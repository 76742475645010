<template>
  <div>
    <form class="auth-form" @submit.prevent="send_recovery_link()">
      <base-logo-wave class="text-center auth-logo" />

      <h2>{{ $t('auth.forgot_password.title') }}</h2>

      <p class="instruction-text" v-html="insctruction_text"></p>

      <base-input
        v-model="data.email"
        type="email"
        :placeholder="$t('auth.forgot_password.email_placeholder')"
        required
        autofocus
        v-if="show_email_input"
      />

      <base-button type="submit" class="btn-block btn-lg" :disabled="disable_submit">
        {{ $t('auth.forgot_password.send_recovery_link_btn') }}
      </base-button>

      <p class="bottom-text text-center">
        {{ $t('auth.forgot_password.want_to_give_it_another_try') }}
        &nbsp;<router-link :to="{ name: 'login' }">{{ $t('auth.forgot_password.log_in') }}</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import BaseLogoWave from '../../components/BaseLogoWave.vue'
import BaseInput from '../../components/BaseInput.vue'
import BaseButton from '../../components/BaseButton.vue'

export default {
  data () {
    return {
      data: {
        email: '',
        redirect_url: process.env.VUE_APP_URL + '/reset-password'
      },
      insctruction_text: this.$t('auth.forgot_password.instruction_text'),
      show_email_input: true,
      disable_submit: false
    }
  },
  components: {
    BaseLogoWave,
    BaseInput,
    BaseButton
  },
  methods: {
    async send_recovery_link () {
      await this.$recaptchaLoaded()

      this.$recaptcha('forgot_password').then(token => {
        this.axios.post('/subscriber_auth/password', this.data).then(response => {
          this.insctruction_text = this.$t('auth.forgot_password.recovery_link_sent') +
          '&nbsp;<span class="' + this.$style.bold + '">' + this.data.email + '</span>.'

          this.$notify({
            type: 'success',
            text: this.insctruction_text
          })

          this.show_email_input = false
          this.disable_submit = true
        }).catch(error => {
          this.data.email = ''
          this.$notify({
            type: 'error',
            text: error.response.data.errors[0] + ' ' + this.$t('common.please_try_again')
          })
        })
      })
    }
  }
}
</script>

<style module lang="scss">
.bold {
  font-weight: bold;
}
</style>

<style scoped lang="scss">
h2 {
  font-family: 'Heebo', arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0px;
  color: var(--dark-grey-white);
  margin-bottom: 10px;
}

p.instruction-text {
  letter-spacing: 0.1px;
  color: var(--charcoal-grey-white);
  font-family: 'Athelas', arial, sans-serif;
  font-size: 16px;
  margin-bottom: 44px;
}
</style>
