import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=449e8b54&scoped=true&"
import script from "./ForgotPassword.vue?vue&type=script&lang=js&"
export * from "./ForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&module=true&lang=scss&"
import style1 from "./ForgotPassword.vue?vue&type=style&index=1&id=449e8b54&scoped=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "449e8b54",
  null
  
)

export default component.exports